/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, SeparateLine } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O mně"}>
        <SiteHeader />

        <Column className="--center pb--50 pt--80" name={"urj63ee7nfn"} style={{"backgroundColor":"rgba(206,178,162,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--43" content={"<span style=\"color: rgb(255, 255, 255);\">O mně</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rvi280goxh"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--36" content={"Jak to všechno začalo..."}>
              </Title>

              <Text className="text-box fs--20" content={"Velkou milovnicí zvířat jsem odjakživa. Své dětství jsem strávila u koní a ve veterinární ordinaci, kam jsem chodila pomáhat. Už v té době jsem měla jasno v tom, co chci dělat. Zřejmě vás tedy nepřekvapí, že následovalo <span style=\"font-weight: bold;\">studium veteriny</span>. Jako vždy jsem se ale rozhodla jít vlastní cestou a zasvětila svůj život primárně psům. Nyní jsem <span style=\"font-weight: bold;\">certifikovaná profesionální trenérka psů</span>. Svou kariéru jsem začala jako hlídačka na plný úvazek. Během svého působení jsem získala mnoho zkušeností a objevila vášeň i pro výcvik psů. Své dovednosti jsem tedy získala jednak studiem, ale i celoživotní prací se zvířaty.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/f0f370b3ff85440d9dbbc5914424af3b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/26402/f0f370b3ff85440d9dbbc5914424af3b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/f0f370b3ff85440d9dbbc5914424af3b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26402/f0f370b3ff85440d9dbbc5914424af3b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26402/f0f370b3ff85440d9dbbc5914424af3b_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--20" name={"eejsyj7w7io"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" content={"Individuální přístup a výcvik zaměřený <br>na pozitivní posilování"}>
              </Title>

              <Text className="text-box text-box--center fs--20" style={{"maxWidth":654}} content={"Mým cílem je poskytovat komplexní a individuální služby přizpůsobené jedinečným potřebám každého psa. <br><br>Stojím si za filozofií, že psi se učí nejlépe, když jsou motivovaní k práci,&nbsp;proto vždy volím pozitivní přístup k tréninku. <br><br>&nbsp;Trénink založený na pozitivním posilování není \"ignorování špatného chování\", ale formujeme chování psů a posiluje ho, aby se rozhodovali co nejlépe. Pokud psům nasloucháme a vedeme je s porozuměním, získáme vztah založený na důvěře a to se snažím poskytnout všem svým klientům.<br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="css-1frsgf1" name={"pmknj6jj81b"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":127}} layout={"l3"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=3000x_.jpg);
    }
  
    `}>
        </SeparateLine>


        <Column className="pb--60 pt--60" name={"zpayubmv2fj"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/9053398a17c54488b090f4c4560aa918_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/26402/9053398a17c54488b090f4c4560aa918_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/9053398a17c54488b090f4c4560aa918_s=660x_.JPG 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/99df51877a0f4d529d087f28603d60a7_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/26402/99df51877a0f4d529d087f28603d60a7_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/99df51877a0f4d529d087f28603d60a7_s=660x_.JPG 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/e6e34b59fc6248ce9526e04dd56e4f20_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/26402/e6e34b59fc6248ce9526e04dd56e4f20_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/e6e34b59fc6248ce9526e04dd56e4f20_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/e6e34b59fc6248ce9526e04dd56e4f20_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/5b7e80cb992342e4a1822413e2e0b3f7_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/26402/5b7e80cb992342e4a1822413e2e0b3f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/5b7e80cb992342e4a1822413e2e0b3f7_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--02" name={"m66rgi6lyp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" content={"Závěrem..."}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":654}} content={"Zvířata jsou zkrátka můj život. Nejen psi jsou dnes jeho nedílnou součástí, ale i jízda na koni je stále jeden z mých největších koníčků. Hlídám také kočky a jiné domácí mazlíčky.&nbsp; <br><br>Je pro mě důležité budovat přátelské a otevřené vztahy nejen se svými zvířecími svěřenci, ale i s jejich majiteli. Vážím si jejich důvěry a respektuji soukromí, do kterého svým způsobem v některých případech zasahuji. Troufnu si říci, že se z některých mých dlouholetých klientů stali i moji přátelé a jsem za to velice vděčná ♡."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Image src={"https://cdn.swbpg.com/o/26402/df60f83ccf2f4be8867ec8bcdeed2086.svg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"info@dogmom.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}